export const sharedUatEnvironment = {
  production: false,
  appVersion: '0.1',
  apiBaseUrl: 'https://api.journeyapp.uat.scrij.com/',
  apiShowroomUrl: 'https://digital-showroom-api.dev.scrij.com/',
  environment: 'uat',
  tinyMceApiKey: '3sz0v4q6pw6iew3ndhgk58gy8kccvujcm1nmtrmjaox21pmr',
  threadsWssUrl: 'wss://atlas-anycable.uat.scrij.com',
  googleApiKey: 'AIzaSyAyHQYUAe3Q3dq5giAaaQeGrr3XiXHiVHo',
  accessCode: 'BC9378011AB6412D08E76A5793F8181A4D716337D96206A4EF19A5E42CD001E8',
  analyticsIdClient: '',
  analyticsIdAdmin: '',
  mixpanelToken: '',
  apiClientUrl: 'https://client.journeyapp.uat.scrij.com/',
  picarioSceneUrl: 'https://journey.picarioxpo.com/',
};
