import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SignicatService {
  constructor(@Inject('ENVIRONMENT') private environment: IEnvironment, private http: HttpClient) {}

  /**
   * @note Upload generated wishlist for signing
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Client-Wishlist/paths/~1api~1v1~1client~1projects~1%7Bproject_id%7D~1units~1%7Bunit_id%7D~1wishlist~1upload_for_signing/get
   */
  public getLinkDocument(projectId: number, unitId: number): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(
      this.environment.apiBaseUrl + `api/v1/client/projects/${projectId}/units/${unitId}/wishlist/upload_for_signing`
    );
  }

  /**
   * @note Check Status
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Client-Signing-Tasks/paths/~1api~1v1~1client~1units~1%7Bunit_id%7D~1signing_tasks~1%7Bexternal_task_id%7D~1check_status/patch
   */
  public checkStatusSigningDocument(unitId: number, taskId: string): Observable<any> {
    return this.http.patch(
      this.environment.apiBaseUrl + `api/v1/client/units/${unitId}/signing_tasks/${taskId}/check_status`,
      {}
    );
  }

  /**
   * @note Upload offer for signing
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Client-ChangeRequests-Offers/paths/
   * ~1api~1v1~1client~1units~1%7Bunit_id%7D~1change_requests~1change_requests~1%7Bchange_request_id%7D~1offers~1%7Bid%7D~1upload_for_signing/get
   */
  changeRequestOfferSignIn(unitId: number, reqId: number, offerId: number): Observable<string> {
    return this.http
      .get<{ url: string }>(
        `${this.environment.apiBaseUrl}api/v1/client/units/${unitId}/change_requests/change_requests/${reqId}/offers/${offerId}/upload_for_signing`
      )
      .pipe(map((res) => res.url));
  }

  /**
   * @note Upload Wishlist for signing in Signicat
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Unit-Options/paths/~1api~1v1~1units~1%7Bunit_id%7D~1wishlist~1upload_for_signing/get
   */
  public getAdminLinkDocument(unitId: number): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(
      this.environment.apiBaseUrl + `api/v1/units/${unitId}/wishlist/upload_for_signing`
    );
  }
}
