import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutoFocusDirective } from './directives/autofocus/autofocus.directive';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { CloseModalEscDirective } from './directives/close-modal-esc/close-modal-esc.directive';
import { DisableInputDropDirective } from './directives/directive/disable-input-drop.directive';
import { InfiniteScrollDirective } from './directives/directive/infinite-scroll.directive';
import { DropdownComponent } from './directives/dropdown-trigger-for/dropdown.component';
import { DropdownTriggerForDirective } from './directives/dropdown-trigger-for/dropdown-trigger-for.directive';
import { ImageResizeDirective } from './directives/image-resize/image-resize.directive';
import { GroupTemplateDirective } from './directives/input-template/group-template.directive';
import {InputHeaderDirective} from './directives/input-template/input-header.directive';
import { InputLabelTemplateDirective } from './directives/input-template/input-label-template.directive';
import { InputNotFoundDirective } from './directives/input-template/input-not-found.directive';
import { InputTemplateDirective } from './directives/input-template/input-template.directive';
import { InputTranslateTemplateDirective } from './directives/input-template/input-translate-template.directive';
import { MultiInputTemplateDirective } from './directives/input-template/multi-input-template.directive';
import { MaxLengthDirective } from './directives/max-length/max-length.directive';
import { NoEmojiDirective } from './directives/no-emoji/no-emoji.directive';
import { PreventDoubleSpaceDirective } from './directives/prevent-space/prevent-space.directive';
import { CapitalLetterDirective } from './directives/start-from-capital-letter/capital-letter.directive';
import { WindowInnerHeightDirective } from './directives/window-inner-height/window-inner-height.directive';
import { ZoomDirective } from './directives/zoom/zoom.directive';

const directives = [
  DisableInputDropDirective,
  InputTemplateDirective,
  InputLabelTemplateDirective,
  InputTranslateTemplateDirective,
  AutoFocusDirective,
  CloseModalEscDirective,
  WindowInnerHeightDirective,
  InputNotFoundDirective,
  DropdownComponent,
  DropdownTriggerForDirective,
  MultiInputTemplateDirective,
  GroupTemplateDirective,
  ImageResizeDirective,
  InputHeaderDirective,
  ZoomDirective,
];

const modularDirectives = [
  ClickOutsideDirective,
  NoEmojiDirective,
  MaxLengthDirective,
  CapitalLetterDirective,
  PreventDoubleSpaceDirective,
  InfiniteScrollDirective,
];

@NgModule({
  imports: [CommonModule, ...modularDirectives],
  declarations: [...directives],
  exports: [...directives, ...modularDirectives],
})
export class SharedDirectivesModule {}
